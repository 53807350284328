import {
  FULFILLMENT_STATUSES,
  ORDER_METHODS,
  ROOM_SERVICE_LOCATION_TYPES,
} from '../../../../../services/exports/Constants';

export default {
  header: 'Order history',
  labels: {
    dummy_order: 'Test order',
    orders_number: 'Number of orders ({{count}})',
    revenue: 'Revenue: {{amount}}',
    tips: 'Tips: {{amount}}',
    cash_sales: ' ({{amount}} Cash)',
    rejected_orders: 'Refunds: {{count}} ({{amount}})',
  },
  order_methods: {
    [ORDER_METHODS.pickup]: 'Pickup',
    [ORDER_METHODS.delivery]: 'Delivery',
    [ORDER_METHODS.room_service]: {
      [ROOM_SERVICE_LOCATION_TYPES.room_service]: 'Room service',
      [ROOM_SERVICE_LOCATION_TYPES.table_service]: 'Table service',
    },
  },
  statuses: {
    [FULFILLMENT_STATUSES.created]: 'In progress',
    [FULFILLMENT_STATUSES.reviewed]: 'In progress',
    [FULFILLMENT_STATUSES.accepted]: 'In progress',
    [FULFILLMENT_STATUSES.preparing]: 'In progress',
    [FULFILLMENT_STATUSES.ready]: 'In progress',
    [FULFILLMENT_STATUSES.on_delivery]: 'In progress',
    [FULFILLMENT_STATUSES.picked]: 'Completed',
    [FULFILLMENT_STATUSES.rejected]: 'Rejected',
  },
  payment_methods: {
    invoice: 'Invoice',
    cash: 'Cash',
    online: 'Online method',
  },
  filters: {
    all: 'All',
  },
  table: {
    headers: {
      order_placed: 'Order placed',
      order_number: '#',
      customer: 'Customer',
      payment_method: 'Payment method',
      method: 'Type',
      status: 'Status',
      total: 'Total',
    },
  },
  buttons: {
    print: 'Print',
    create_test_order: 'Create test order',
  },
  toasts: {
    failed_to_fetch_orders: 'Failed to load data. Please click on “refresh” icon or try again in 1 minute.',
    something_went_wrong: 'Something went wrong, please try again.',
    failed_to_create_test_order: 'Failed to create test order',
    test_order_created: 'Test order created',
  },
};
