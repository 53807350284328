export const EventHelperTypes = {
  LOG_OUT: 'Log Out',
  DISPLAY_CONFIRMATION_MODAL: 'DISPLAY_CONFIRMATION_MODAL',
};

export const ORDER_METHODS = {
  pickup: 'pickup',
  delivery: 'delivery',
  room_service: 'room_service',
};

export const UtmSource = {
  FoodAmigosOCC: 'foodamigos_occ',
};

export const ORDER_PAYMENT_STATUSES = {
  pending: 'pending',
  paid: 'paid',
  partially_refunded: 'partially_refunded',
  refunded: 'refunded',
};

export const FULFILLMENT_STATUSES = {
  created: 'created',
  reviewed: 'reviewed',
  accepted: 'accepted',
  preparing: 'preparing',
  ready: 'ready',
  on_delivery: 'on_delivery',
  picked: 'picked',
  rejected: 'rejected',
};

export const ROOM_SERVICE_LOCATION_TYPES = {
  room_service: 'room_service',
  table_service: 'table_service',
};

export const ORDERS_PAUSE_REASONS = {
  manual: 'manual',
  inactivity: 'inactivity',
};

export const ORDER_REJECTION_REASONS = {
  Automatic: 'auto',
  ClosingSoon: 'closing_soon',
  ItemUnavailable: 'item_unavailable',
  StoryBusy: 'store_busy',
  OrderTooBig: 'order_too_big',
  CustomerMistake: 'customer_mistake',
  DriverNotArrived: 'driver_not_arrived',
  Other: 'other',
};

export const AVAILABLE_LANGUAGES = [
  {
    language: 'de',
    languageName: 'German',
  },
  {
    language: 'en',
    languageName: 'English',
  },
];

export const ROUTES = {
  home: '/',
  order_history: '/order-history',
  menu: '/menu',
  printer_settings: '/printer-settings',
  order_control: '/order-control',
  busyMode: '/busy-mode',
  tips: '/tips',
  login: '/login',
  logout: '/logout',
};

export const EVENT_HELPER_TYPES = {
  OPEN_CLOSE_ORDER_MODAL: 'Open CloseOrderModal',
  OPEN_ORDER_DETAILS_MODAL: 'Open OrderDetailsModal',
};

export const PUSHER_EVENT_TYPES = {
  FORCE_REFRESH: 'force_refresh',
  ORDER_PAID: 'order.paid',
  ORDER_REFUNDED: 'order.refunded',
  ORDER_UPDATED: 'order.updated',
  PRINT_ORDER: 'order.print',
  ORDER_PICKUP_TIME_OPTIMIZED: 'order.pickup_time_optimized',
  ORDER_COMPLETE: 'order.complete',
  REFUND_CREATED: 'refund.created',
  ORDERS_PAUSED: 'orders.paused',
  ORDERS_UNPAUSED: 'orders.unpaused',
};

export const APP_ENVIRONMENTS = {
  DEV: 'dev',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

export const PUSHER_CONNECTION_STATES = {
  INITIALIZED: 'initialized',
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  UNAVAILABLE: 'unavailable',
  FAILED: 'failed',
  DISCONNECTED: 'disconnected',
};

export const REFUND_REASONS = {
  RequestedByCustomer: 'requested_by_customer',
  Reclamation: 'reclamation',
  CommunicationError: 'communication_error',
  Dispute: 'dispute',
  Other: 'other',
};

export const WEBVIEW_COMMUNICATOR_COMMANDS = {
  SAVE_WEB_APP_AUTH_TOKEN: 'SAVE_WEB_APP_AUTH_TOKEN',
  ENTER_TEST_MODE: 'ENTER_TEST_MODE',
  EXIT_TEST_MODE: 'EXIT_TEST_MODE',
  SAVE_LANGUAGE: 'SAVE_LANGUAGE',
  PLAY_SOUND: 'PLAY_SOUND',
  PLAY_SCHEDULED_ORDER_REMINDER_SOUND: 'PLAY_SCHEDULE_ORDER_REMINDER_SOUND',
  PRINT_ORDER: 'PRINT_ORDER',
  NAVIGATE: 'NAVIGATE',
};

export const COUPON_TYPES = {
  REWARD: 1,
  CASHBACK: 2,
  PROMO_CODE: 3,
  BOGO_CAMPAIGN: 4,
};

export const PAYMENT_METHODS = {
  CARD: 'card',
  PAYPAL: 'paypal',
  APPLE_PAY: 'apple_pay',
  GOOGLE_PAY: 'google_pay',
  SOFORT: 'sofort',
  GIROPAY: 'giropay',
  SEPA_DEBIT: 'sepa_debit',
  CASH: 'cash',
  bacs_debit: 'bacs_debit',
};

export const RECEIPT_TYPE = {
  ORDER: 'order',
  ORDER_HISTORY: 'order_history',
};

export const INNER_PRINTER_RECEIPT_ELEMENT_ID = 'inner-printer-receipt';

export const THERMAL_PRINTER_SIZES = {
  FIFTY_EIGHT_MM: '384',
};

export const TAX_BEHAVIOUR = {
  inclusive: 'inclusive',
  exclusive: 'exclusive',
};

export const DeliveryMode = {
  Internal: 'internal',
  External: 'external',
};

export const DeliveryProvider = {
  Standalone: 'standalone',
  FirstDelivery: 'first_delivery',
  UberDrive: 'uber_drive',
  WoltDrive: 'wolt_drive',
};

export const DeliveryCarrier = {
  Uber: 'uber',
  Wolt: 'wolt',
};

export const DeliveryStatus = {
  Pending: 'pending',
  RunnerAssigned: 'runner_assigned',
  OnRoutePickup: 'en_route_pickup',
  ArrivedPickup: 'arrived_pickup',
  PickedUp: 'picked_up',
  OnRouteDropoff: 'en_route_dropoff',
  ArrivedDropoff: 'arrived_dropoff',
  Completed: 'completed',
  Cancelled: 'cancelled',
};

export const ERROR_CODES = {
  no_delivery_quotes_available: 'no_delivery_quotes_available',
  delivery_provider_exception: 'delivery_provider_exception',
};

export const MODIFIER_ENTRY_TYPE = Object.freeze({
  PRODUCT: 'product',
  MODIFIER: 'modifier',
});
