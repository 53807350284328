import { DeliveryProvider, FULFILLMENT_STATUSES, ORDER_METHODS } from '../../../../../../services/exports/Constants';

export default {
  text: {
    status: {
      [FULFILLMENT_STATUSES.rejected]: 'Are you sure?',
    },
    [ORDER_METHODS.pickup]: 'Has the customer already picked up the order?',
    [ORDER_METHODS.room_service]: 'Has the customer already picked up the order?',
    [ORDER_METHODS.delivery]: {
      [DeliveryProvider.Standalone]: 'Has the the order been delivered already?',
      [DeliveryProvider.UberDrive]: 'Has the driver already picked up the order?',
      [DeliveryProvider.FirstDelivery]: 'Has the driver already picked up the order?',
      [DeliveryProvider.WoltDrive]: 'Has the driver already picked up the order?',
    },
  },
  buttons: {
    cancel: 'No, Cancel',
    confirm: 'Yes',
  },
};
