import { CCol, CRow } from '@coreui/react';
import React, { Fragment, useMemo } from 'react';
import { collect } from 'collect.js';
import tw from 'twin.macro';
import styled from 'styled-components';
import Variant from './Variant';
import useHelpers from '../../hooks/useHelpers';
import Modifier from './Modifier';

export default function OrderLineItem({ lineItem, isLastItem }) {
  const { formatCurrency, formatProductName } = useHelpers();

  const { modifier_groups, modifiers } = lineItem;
  const variantModifierGroup = collect(modifier_groups).first((item) => item.is_variant === true);
  const variant = variantModifierGroup
    ? collect(lineItem.modifiers).first((item) => item.modifier_group_id === variantModifierGroup.id)
    : null;
  const modifiersByParent = useMemo(() => collect(modifiers).groupBy('parent_uuid'), [modifiers]);
  const rootModifiers = useMemo(() => modifiersByParent.get(''), [modifiersByParent]);
  const renderModifier = (item, isNested = false) => {
    return (
      <Fragment key={item.uuid}>
        <Modifier name={item.name} price={item.original_price} quantity={item.quantity} isNested={isNested} />
        {renderNestedModifiers(item.uuid)}
      </Fragment>
    );
  };
  const renderNestedModifiers = (parentUuid) => {
    const nestedModifiers = modifiersByParent.get(parentUuid);

    if (!nestedModifiers?.count()) {
      return;
    }

    return <div className="ms-3">{nestedModifiers.map((modifier) => renderModifier(modifier, true))}</div>;
  };

  return (
    <>
      <CRow className="mt-3">
        <CCol md={1} sm={1} xs={1}>
          <div className="website-text bold">{lineItem.quantity}x</div>
        </CCol>
        <CCol md={9} sm={8} xs={8}>
          <div className="website-text bold break-word">{formatProductName(lineItem)}</div>
        </CCol>
        <CCol md={2} sm={3} xs={3}>
          <div className="text-right website-text">{formatCurrency(lineItem.product_original_price)}</div>
        </CCol>
      </CRow>
      {rootModifiers?.map((item) => renderModifier(item))}
      {variant && <Variant name={variant.name} price={variant.original_price * lineItem.quantity} />}
      {lineItem.note && (
        <CRow className="mt-1">
          <CCol xs={1} />
          <CCol xs={11}>
            <div className="website-text italic">{lineItem.note}</div>
          </CCol>
        </CRow>
      )}
      {!isLastItem && <Divider />}
    </>
  );
}

const Divider = styled.hr`
  ${tw`w-full mt-2 mb-0 bg-silver`}
  height: 1px;
`;
