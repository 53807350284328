import React from 'react';
import { CCol, CRow } from '@coreui/react';
import useHelpers from '../../hooks/useHelpers';
import tw from 'twin.macro';

export default function Modifier({ name, price, quantity, isNested }) {
  const { formatCurrency } = useHelpers();
  const modifierIcon = isNested ? '+' : '•';
  const quantityTitle = ` ${quantity}x `;

  return (
    <CRow className="mt-1">
      <CCol md={1} sm={1} xs={1} />
      <CCol md={9} sm={8} xs={8}>
        <div className="ps-3 website-text">
          {modifierIcon}
          <Paragraph>{quantityTitle}</Paragraph>
          {name}
        </div>
      </CCol>
      <CCol md={2} sm={3} xs={3}>
        <div className="text-right website-text">{formatCurrency(price * quantity)}</div>
      </CCol>
    </CRow>
  );
}

const Paragraph = tw.span`font-bold`;
