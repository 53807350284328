import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { HelpersContext } from '../contexts/HelpersContext';
import HelperMethods from '../services/exports/HelperMethods';

export default function HelpersProvider({ children }) {
  const { company } = useSelector((state) => state.company);

  useEffect(() => {
    Object.defineProperty(String.prototype, 'capitalize', {
      value() {
        return this.charAt(0).toUpperCase() + this.slice(1);
      },
      enumerable: false,
    });
  }, []);

  const formatCurrency = useCallback(
    (amount, currency = null, locale = null) =>
      HelperMethods.formatCurrency(amount, currency ?? company?.currency, locale),
    [company?.currency],
  );

  const formatTime = useCallback(
    (datetime, format = undefined) => {
      const _format = format ?? (company?.country === 'US' ? 'h:mm a' : 'HH:mm');

      return moment(datetime).format(_format);
    },
    [company?.country],
  );

  const formatDateTime = useCallback(
    (datetime, { format = undefined, dateFormat = undefined, timeFormat = undefined } = {}, language = null) => {
      const _dateFormat = dateFormat ?? 'DD.MM.YYYY';
      const _timeFormat = timeFormat ?? (company?.country === 'company?.country' ? 'h:mm a' : 'HH:mm');
      const _format = format ?? `${_dateFormat} ${_timeFormat}`.trim();

      let _datetime = moment(datetime);

      if (language) {
        _datetime = _datetime.locale(language);
      }

      return _datetime.format(_format);
    },
    [company?.country],
  );

  const formatProductName = useCallback(
    (product) =>
      company?.has_internal_product_numbers ? `${product.name} (#${product.internal_number})` : product.name,
    [company?.has_internal_product_numbers],
  );

  const formatAmount = (cents) => (cents ?? 0) / 100;

  const context = useMemo(
    () => ({
      formatCurrency,
      formatProductName,
      formatTime,
      formatDateTime,
      formatAmount,
    }),
    [formatCurrency, formatProductName, formatTime, formatDateTime],
  );

  return <HelpersContext.Provider value={context}>{children}</HelpersContext.Provider>;
}
