import { DeliveryProvider, FULFILLMENT_STATUSES, ORDER_METHODS } from '../../../../../../services/exports/Constants';

export default {
  text: {
    status: {
      [FULFILLMENT_STATUSES.rejected]: 'Sind Sie sicher?',
    },
    [ORDER_METHODS.pickup]: 'Hat der Kunde die Bestellung bereits abgeholt?',
    [ORDER_METHODS.room_service]: 'Hat der Kunde die Bestellung bereits abgeholt?',
    [ORDER_METHODS.delivery]: {
      [DeliveryProvider.Standalone]: 'Hast du die Bestellung bereits ausgeliefert?',
      [DeliveryProvider.UberDrive]: 'Hat der Fahrer die Bestellung bereits abgeholt?',
      [DeliveryProvider.FirstDelivery]: 'Hat der Fahrer die Bestellung bereits abgeholt?',
      [DeliveryProvider.WoltDrive]: 'Hat der Fahrer die Bestellung bereits abgeholt?',
    },
  },
  buttons: {
    cancel: 'Nein, Abbrechen',
    confirm: 'Ja',
  },
};
